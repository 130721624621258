import React from "react"
import { Link } from "gatsby"
import { useLocation } from '@reach/router'
import styled from "styled-components"

const BreadcrumbNav = styled.nav`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`

const BreadcrumbList = styled.ol`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`

const BreadcrumbItem = styled.li`
  margin-right: 0.5rem;
font-size:12px;
  &:not(:last-child)::after {
    content: ">";
    margin-left: 0.5rem;
  }
`

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  color: blue;

  &:hover {
    text-decoration: underline;
  }
`

const Breadcrumb = () => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter(x => x)

  return (
    <BreadcrumbNav aria-label="breadcrumb">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink to="/">Home</BreadcrumbLink>
        </BreadcrumbItem>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
          const isLast = index === pathnames.length - 1
          return isLast ? (
            <BreadcrumbItem key={name}>
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={name}>
              <BreadcrumbLink to={routeTo}>
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )
        })}
      </BreadcrumbList>
    </BreadcrumbNav>
  )
}

export default Breadcrumb
