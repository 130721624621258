import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Box, Text } from "../../components/Core";
import styled from 'styled-components';
import Breadcrumb from "../../components/breadcrumb";

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalLine = styled.div`
  width: 4px;
  height: 1.5em; /* Matches the approximate height of the text */
  background-color: #00aec7;
  margin-right: 20px;
`;

const TitleText = styled.h1`
  margin: 0;
  font-size: 24px;
  color: #333;
`;

const Normal = styled.p`
  margin: 0;
  font-size: 14px;
  color: #333;
  line-height: 25px;
`;

const InfoSection = ({ title, content }) => {
  return (
    <>
    <Section bg="#dafafe" py={4}>
      <Container className="pt-5">
        <Row className="justify-content-center mt-3 pt-lg-5">
          <Col className="mb-lg-0">
            <Breadcrumb /> <br />
            <TitleWrapper>
              <VerticalLine />
              <TitleText>{title}</TitleText>
            </TitleWrapper> <br />
            <Normal>{content}</Normal>
          </Col>
        </Row>
      </Container>
    </Section>
    </>
  );
};

export default InfoSection;
