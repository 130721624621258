import React from "react"
import PageWrapper from "../../components/PageWrapper"
import Treat from "../../sections/treat/treat.js"
import TreatComponent from "../../sections/treat/treatComponent.js"

const microneedlingSections = [
    {
      id: 'overview',
      title: 'Overview',
      content: `
        Microneedling is a minimally invasive cosmetic procedure that involves the use of thin needles to create tiny punctures in the skin. This process stimulates the body's natural healing response, promoting the production of collagen and elastin. Microneedling is commonly used to treat: <br /> <br/>
        <ul>
          <li>Acne scars</li>
          <li>Stretch marks</li>
          <li>Fine lines and wrinkles</li>
          <li>Enlarged pores</li>
          <li>Uneven skin tone</li>
        </ul>
      `
    },
    {
      id: 'benefits',
      title: 'Benefits',
      content: `
        <ul>
          <li>Stimulates collagen production</li>
          <li>Improves skin texture and tone</li>
          <li>Minimizes scars and stretch marks</li>
          <li>Suitable for all skin types</li>
          <li>Minimal downtime</li>
        </ul>
      `
    },
    {
      id: 'comparison',
      title: 'Comparison',
      content: `
        Both microneedling and dermal fillers are popular treatments for skin rejuvenation, but they work in different ways and address different concerns. <br /><br />
        <b>Microneedling:</b> <br />
        <ul>
          <li>Uses: Acne scars, fine lines, stretch marks, enlarged pores</li>
          <li>Mechanism: Stimulates collagen production by creating micro-injuries</li>
          <li>Results: Gradual improvement over several sessions</li>
          <li>Downtime: Minimal (redness and swelling for a few days)</li>
        </ul><br />
        <b>Dermal Fillers:</b><br />
        <ul>
          <li>Uses: Volume loss, deep wrinkles, facial contours</li>
          <li>Mechanism: Injects hyaluronic acid or other substances to add volume</li>
          <li>Results: Immediate improvement</li>
          <li>Downtime: Minimal (possible bruising and swelling for a few days)</li>
        </ul>
      `
    },
    {
      id: 'who-should-consider',
      title: 'Candidates',
      content: `
        Microneedling is suitable for individuals with various skin concerns looking for a non-invasive treatment option. However, it may not be recommended for those with:<br /><br />
        <ul>
          <li>Active acne</li>
          <li>Blood disorders</li>
          <li>Certain skin conditions like eczema or psoriasis</li>
          <li>Recent radiation therapy</li>
        </ul>
      `
    },
    {
      id: 'aftercare',
      title: 'Aftercare',
      content: `
        Post-treatment, patients might experience redness and slight swelling, similar to a mild sunburn, for a few days. It is crucial to avoid direct sun exposure and to use a gentle skincare routine during the healing process.
      `
    },
    {
      id: 'consult',
      title: 'Consultation',
      content: `
        Contact your healthcare provider if you experience any unusual symptoms post-treatment, such as prolonged bleeding, blistering, or extended swelling.
      `
    },
    {
      id: 'conclusion',
      title: 'Conclusion',
      content: `
        Microneedling is a versatile and effective treatment for improving skin texture and reducing various imperfections. While it offers gradual, natural-looking results, dermal fillers provide immediate volume and contouring benefits. Consult with a certified professional to determine the best treatment for your specific skin needs.
      `
    }
  ];

const MicroneedlingPage = () => (
    <>
  <PageWrapper headerDark footerDark>

   
<Treat 
        title="Microneedling" 
        content="Microneedling involves the use of fine needles to create micro-injuries on the skin, promoting collagen production and skin rejuvenation. This minimally invasive treatment is effective in diminishing the visibility of acne scars, stretch marks, and wrinkles. While commonly sought for aesthetic improvements, microneedling can also be beneficial for certain medical conditions."
      />

<TreatComponent sections={microneedlingSections} />
   
  </PageWrapper>
  </>
)

export default MicroneedlingPage
