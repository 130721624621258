import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalLine = styled.div`
  width: 4px;
  height: 1.5em; /* Matches the approximate height of the text */
  background-color: #00aec7;
  margin-right: 20px;
`;

const TitleText = styled.h1`
  margin: 0;
  font-size: 24px;
  color: #333;
`;

const Normal = styled.div`
  margin: 20px 0 0 0; /* Adds spacing between the title and text */
  font-size: 14px;
  color: #333;
  line-height: 25px;
`;

const AnchorLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const AnchorLink = styled.a`
  text-decoration: none;
  color: #6ca0dc !important; /* Lighter blue color */
  font-weight: bold;
`;

const InfoText = styled.div`
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
`;

const SectionWrapper = styled.section`
  padding: 40px 0;
  border-bottom: 1px solid #EAE9F2;
  background-color: ${({ isEven }) => (isEven ? '#f7f7fb' : '#ffffff')};
`;

const InfoSection = ({ id, title, content, isEven }) => (
  <>
  <SectionWrapper id={id} isEven={isEven}>
    <Container>
      <Row className="justify-content-center">
        <Col>
          <TitleWrapper>
            <VerticalLine />
            <TitleText>{title}</TitleText>
          </TitleWrapper>
          <Normal dangerouslySetInnerHTML={{ __html: content }} />
        </Col>
      </Row>
    </Container>
  </SectionWrapper>
  </>
);

const TreatmentComponent = ({ sections }) => (
  <div>
    <Container className='pt-5 pb-4'>
      <AnchorLinks>
        <InfoText>
          Information ↓
        </InfoText>
        {sections.map((section, index) => (
          <AnchorLink key={index} href={`#${section.id}`}>{section.title}</AnchorLink>
        ))}
      </AnchorLinks>
    </Container>
    <div>
      {sections.map((section, index) => (
        <InfoSection key={index} id={section.id} title={section.title} content={section.content} isEven={index % 2 === 0} />
      ))}
    </div>
  </div>
);

export default TreatmentComponent;
